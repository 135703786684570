import React, { useState } from 'react';
import './styles/Stories.css';

// for buttons 
import Button from 'react-bootstrap/Button';

// for captions
import Bar from './assets/images/bar.png';
import { logToMatomo } from './utils/matomoOperations.js'


function Stories() {
    const [selected, setSelected] = useState("1");

    const handleButton = (valueSelected) => {
        setSelected(valueSelected); /* selected = value of button */
    };

    return (
        <div id="Stories" className='stories-background'>
            <h1 className='stories-title'>Cloud One Mission Success Stories</h1>
            <div className='stories-subsection'>
                <div className='stories-buttons-group'>
                <Button variant="primary" onClick={() => {handleButton("5"); logToMatomo(['trackEvent', 'view', 'TEST'])}} className={(selected === "5" ? 'stories-button button-select':'stories-button button-deselect')}>
                        <div className='circle-title'>
                            <div className={(selected === "5" ? 'circle circle-select':'circle circle-deselect')}>1</div>
                            <h3>PACT</h3>
                        </div>
                    </Button>
                <Button variant="primary" onClick={() => {handleButton("1"); logToMatomo(['trackEvent', 'view', 'Digital University'])}} className={(selected === "1" ? 'stories-button button-select':'stories-button button-deselect')}>
                        <div className='circle-title'>
                            <div className={(selected === "1" ? 'circle circle-select':'circle circle-deselect')}>2</div>
                            <h3>Digital University</h3>
                        </div>
                    </Button>
                    <Button variant="primary" onClick={() => {handleButton("2"); logToMatomo(['trackEvent', 'view', 'Air University'])}} className={(selected === "2" ? 'stories-button button-select':'stories-button button-deselect')}>
                        <div className='circle-title'>
                            <div className={(selected === "2" ? 'circle circle-select':'circle circle-deselect')}>3</div>
                            <h3>Air University</h3>
                        </div>
                    </Button>
                    <Button variant="primary" onClick={() => {handleButton("3"); logToMatomo(['trackEvent', 'view', 'ELICSAR'])}} className={(selected === "3" ? 'stories-button button-select':'stories-button button-deselect')}>
                        <div className='circle-title'>
                            <div className={(selected === "3" ? 'circle circle-select':'circle circle-deselect')}>4</div>
                            <h3>ELICSAR</h3>
                        </div>
                    </Button>
                    <Button variant="primary" onClick={() => {handleButton("4"); logToMatomo(['trackEvent', 'view', 'Aim High'])}} className={(selected === "4" ? 'stories-button button-select':'stories-button button-deselect')}>
                        <div className='circle-title'>
                            <div className={(selected === "4" ? 'circle circle-select':'circle circle-deselect')}>5</div>
                            <h3>Aim High</h3>
                        </div>
                    </Button>
                </div>
                <div className='stories-videos-captions'>
                    <div className='stories-videos-group'>
                        <div className={(selected === "1" ? 'stories-video AUvideo' : selected === "2" ? 'stories-video ELICSARvideo' : 'stories-video AIMvideo')}>
                        {selected === "5" &&
                        <video
                            className="videoMask"
                            width="100%"
                            height="100%"
                            controls
                            muted
                            loop
                            playsInline
                            preload="none"
                            poster={`${process.env.PUBLIC_URL}/videos/pact.png`}
                        >
                            <source
                            src={`${process.env.PUBLIC_URL}/videos/pact.mp4`}
                            type="video/mp4"
                            />
                            Your browsers does not support the HTML5 video tag
                        </video> 
                        }
                        {selected === "1" &&
                        <video
                            className="videoMask"
                            width="100%"
                            height="100%"
                            controls
                            muted
                            loop
                            playsInline
                            preload="none"
                            poster={`${process.env.PUBLIC_URL}/videos/du_img.png`}
                        >
                            <source
                            src={`${process.env.PUBLIC_URL}/videos/du_story.mp4`}
                            type="video/mp4"
                            />
                            Your browsers does not support the HTML5 video tag
                        </video> 
                        } 
                        {selected === "2" &&
                        <video
                            className="videoMask"
                            width="100%"
                            height="100%"
                            controls
                            muted
                            loop
                            playsInline
                            preload="none"
                            poster={`${process.env.PUBLIC_URL}/videos/au.jpg`}
                        >
                            <source
                            src={`${process.env.PUBLIC_URL}/videos/c1_au_eems.mp4`}
                            type="video/mp4"
                            />
                            Your browsers does not support the HTML5 video tag
                        </video> 
                        }
                        {selected === "3" &&
                        <video
                            className="videoMask"
                            width="100%"
                            height="100%"
                            controls
                            muted
                            loop
                            playsInline
                            preload="none"
                            poster={`${process.env.PUBLIC_URL}/videos/elic.jpg`}
                        >
                            <source
                            src={`${process.env.PUBLIC_URL}/videos/elicsar.mp4`}
                            type="video/mp4"
                            />
                            Your browsers does not support the HTML5 video tag
                        </video> 
                        }   
                        {selected === "4" &&
                        <video
                            className="videoMask"
                            width="100%"
                            height="100%"
                            controls
                            muted
                            loop
                            playsInline
                            preload="none"
                            poster={`${process.env.PUBLIC_URL}/videos/aim-high.jpg`}
                        >
                            <source
                            src={`${process.env.PUBLIC_URL}/videos/aimhigh.mp4`}
                            type="video/mp4"
                            />
                            Your browsers does not support the HTML5 video tag
                        </video> 
                        }                   
                        </div>
                    </div>
                    <div className='stories-captions-group'>
                        {/* list of captions that 2/3 are always set to display:none and that is updated by buttons */}
                        <div className="stories-caption" style={{ display:(selected === "5" ? '':'none') }}>
                            <h3>PACT - Self-Migration Enagled by High Touch Support</h3>
                            <div className='stories-bar-text'>
                                <img src={Bar} alt="Bar" style={{height: "45px",}}/>
                                <p style={{fontSize: "18px", width: "88%", lineHeight: "28px"}}>The Part Assessment and Cost Tool (PACT) is a decision support tool developed by the Advanced Manufacturing Program Office (AMPO) that is used for assessing whether a part in the supply chain is ideal for an advanced manufacturing solution. Find out about PACT's journey to Cloud One as they shifted to a serverless architecture leveraging cloud capabilities as well as their plan for expansion in Cloud One.</p>
                            </div>
                        </div>
                        <div className="stories-caption" style={{ display:(selected === "1" ? '':'none') }}>
                            <h3>Digital University - Built for the Cloud with Anytime Access | Safe and Secure</h3>
                            <div className='stories-bar-text'>
                                <img src={Bar} alt="Bar" style={{height: "105px",}}/>
                                <p style={{fontSize: "18px", width: "88%", lineHeight: "28px"}}>Digital University is a cloud-native application that provides anytime access to tens of thousands of commercial cyber training content and fosters a community of tens of thousands of learners among tomrrow’s warfighter. Digital University is protected by Cloud One’s Zero trust delivery and security. Watch now (3:24 min video)</p>
                            </div>
                        </div>
                        <div className="stories-caption" style={{ display:(selected === "2" ? '':'none') }}>
                            <h3>Air University - Embracing the Change</h3>
                            <div className='stories-bar-text'>
                                <img src={Bar} alt="Bar" style={{height: "75px"}}/>
                                <p style={{fontSize: "18px", width: "85%", lineHeight: "28px"}}>Find out how Air University, the lead for Air Force education, successfully migrated their complex learning environment that interfaces with multiple systems into Cloud One and how they continue to experience a return on their investment.</p>
                            </div>
                        </div>
                        <div className="stories-caption" style={{ display:(selected === "3" ? '':'none') }}>
                            <h3>ELICSAR - Incremental Growth...Incremental Savings</h3>
                            <div className='stories-bar-text'>
                                <img src={Bar} alt="Bar" style={{height: "105px"}}/>
                                <p style={{fontSize: "16px", width: "84%", lineHeight: "28px"}}>Enterprise Logging Ingest and Cyber Situational Awareness Refinery system (ELICSAR) is a scalable platform that provide cyber awareness of networks. It is the only capability in the Air Force that can facilitate data search, query, and analysis at the Petabyte scale. Find out how they effectively managing the costs of this massive amount of capacity through the use of cost optimization techniques and reserved instances.</p>
                            </div>
                        </div>
                        <div className="stories-caption" style={{ display:(selected === "4" ? '':'none') }}>
                            <h3>Aim High - Maintaining Vital Relationships</h3>
                            <div className='stories-bar-text'>
                                <img src={Bar} alt="Bar" style={{height: "45px",}}/>
                                <p style={{fontSize: "18px", width: "88%", lineHeight: "28px"}}>The Aim High mobile recruiting application is a cloud native system enabled by Cloud One to helped the Air Force connect recruits and personnel with their loved ones, especially during COVID.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Stories;