import React from 'react';
import './styles/Services.css';

// Services Section //
import servicesBlueIcon from './assets/images/Background_services.png'; 
import servicesWhiteOutline from './assets/images/Background_services-line.png';
import AWSLogo from './assets/images/Vendors_AWS.png';
import GoogleCloudLogo from './assets/images/Vendors_Google Cloud.png'; 
import OracleLogo from './assets/images/Vendors_Oracle Cloud Infrastructure.png'; 
import AzureLogo from './assets/images/Vendors_Microsoft Azure.png'; 
import Bar from './assets/images/bar.png';
import Grid from '@mui/material/Grid';
import { logToMatomo } from './utils/matomoOperations.js'


// Service Offerings Section //
import AutoScaling from './assets/images/Icon_service offerings-01.png';
import BackupRecovery from './assets/images/Icon_service offerings-02.png';
import LoadBalancing from './assets/images/Icon_service offerings-03.png';
import SystemSupport from './assets/images/Icon_service offerings-04.png';
import AutomatedMA from './assets/images/Icon_service offerings-05.png';
import Compliance from './assets/images/Icon_service offerings-06.png';
import CSSP from './assets/images/Icon_service offerings-07.png';
import Monitoring from './assets/images/Icon_service offerings-08.png';
import Operating from './assets/images/Icon_service offerings-09.png';
import DevSecOps from './assets/images/Icon_service offerings-10.png';
import AutomatedSVM from './assets/images/Icon_service offerings-11.png';
import IAM from './assets/images/Icon_service offerings-12.png';
import Collaboration from './assets/images/Icon_service offerings-13.png';

// Development Section //
import DevelopmentOverlay from './assets/images/Text_header-development.png';
import DevIcon2 from './assets/images/Icon_development-2.png';
import DevIcon3 from './assets/images/Icon_development-3.png';

// Migrations Section //
import SCCA from './assets/images/Icon_migration-1.png';
import HybridMig from './assets/images/Icon_migration-2.png';
import CSSPmig from './assets/images/Icon_migration-3.png';
import CloudConnect from './assets/images/Icon_migration-4.png';
import Analysis from './assets/images/Icon_migration-5.png';
import ATO from './assets/images/Icon_migration-6.png';
import CnS from './assets/images/Icon_migration-7.png';
import CommonTools from './assets/images/Icon_migration-8.png'; 
import Box from '@mui/material/Box';
function Services() {
    return (
        <div id="Services" className="services-section">

             <Box sx={{ flexGrow: 1 }} className="services-mobile">
                    <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
                    <div>
                        <h1>SERVICES</h1>
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
                    <p style={{color: "rgba(62, 104, 189, 0.866)"}}>Developing, delivering, and managing your App requires industry best standards and compliance.</p>
                        </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
                    <p>Cloud One offers Government Cloud Service Providers (CSPs) in order to deliver the benefits readily available to commercial cloud consumers. We offer these same platforms in our secure environment.</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
                        <p>To learn more about our detailed platform specifications, become a registered user by clicking the “Registered User Login” to validate your DoD credentials for access to our Cloud One customer portal.</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
                        <p>In addition to our best-in-class cloud platforms, we provide accredited services and enterprise governance that mission application owners can take advantage of in your specific Cloud One environment. We call this our “sweet-spot” service model. </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} key={3} >
                        <p >This Platform as a Service (PaaS) model offers an ideal balance of mission application self-management and best practice, DISA approved “guardrails,” allowing your team to focus on your application instead of spending valuable time managing the hosting environments and its underlying infrastructure.</p>
                        </Grid>
                        </Grid>
                        </Box>

                        <Box sx={{ flexGrow: 1 }} className="services-mobile">
                    <Grid container >
                    <Grid item xs={12} sm={12} md={12} lg={12} key={4} >
                    <div>
                        <h1 id="Offerings">SERVICE OFFERINGS</h1>
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} key={4} >
                    <p style={{color: "rgba(62, 104, 189, 0.866)"}}>Our subset of commercial cloud services, from our CSP partnerships, are DISA approved for Impact Levels (IL) 2, 4, 5, 6 and include:</p>
                    <div className="left-list">
                                <div>
                                    <p >Auto-scaling capacity to match demand, offering optimized performance for the lowest available cost</p>
                                </div>
                                <div>
                                    <p>Data Backup & Recovery</p>
                                </div>
                                <div>
                                    <p>Load balancing of traffic to improve application responsiveness and minimize downtime</p>
                                </div>
                                <div>
                                    <p>System Update & Patch Support</p>
                                </div>
                                <div >
                                    <p>Automated Monitoring & Alerting</p>
                                </div>
                            </div>
                        </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} key={4} >
                    <p style={{color: "rgba(62, 104, 189, 0.866)"}}>Cloud One supplements CSP commercial offerings with our own suite of common services tailored specifically to meet the requirments of our DoDs Air Force environment and include: </p>
                    <div className='left-list'>
                                    <div>
                                        <p>Compliance & Accreditation</p>
                                    </div>
                                    <div>
                                        <p>Cyber Security Service Provider (CSSP) Integration</p>
                                    </div>
                                    <div>
                                        <p>Monitoring & Logging</p>
                                    </div>
                                    <div>
                                        <p>Operating Analytics</p>
                                    </div>
                                    <div>
                                        <p>DevSecOps</p>
                                    </div>
                                    <div>
                                        <p>Automated Security & Vulnerability Management</p>
                                    </div>
                                    <div>
                                        <p>Identity & Access Management</p>
                                    </div>
                                    <div>
                                        <p>Collaboration & Support</p>
                                    </div>
                                </div>
                        </Grid>
                        
                        
                        </Grid>
                        </Box>

            <img className="services-icon" src={servicesBlueIcon} alt="Services Icon" />
            <img className="services-icon-outline" src={servicesWhiteOutline} alt="Services Icon Outline" />

            <div className="content-stack">

                <div className="services-content">
                <Grid item xs={12} sm={12} md={12} lg={12} key={1} >
                    <h2 className="services-title">SERVICES</h2>
                    <div className='bar-text' style={{marginLeft: '-1vw', marginTop: "3vh"}}> 
                        <img src={Bar} alt="Bar" style={{width: "8px", marginLeft: "0vw"}}/>
                        <p className="blue-text" style={{marginTop: "-5%", marginLeft: "2%"}}>Developing, delivering, and managing your App requires industry best standards and compliance.</p>
                    </div>
                    </Grid>
                    <div className='grey-text'>
                        <p className="grey-text1">Cloud One offers Government Cloud Service Providers (CSPs) in order to deliver the benefits readily available to commercial cloud consumers. We offer these same platforms in our secure environment.</p>
                        <p className="grey-text2"></p>
                        <p className="grey-text3">In addition to our best-in-class cloud platforms, we provide accredited services and enterprise governance that mission application owners can take advantage of in your specific Cloud One environment. We call this our “sweet-spot” service model. </p>
                        <p className="grey-text4">This Platform as a Service (PaaS) model offers an ideal balance of mission application self-management and best practice, DISA approved “guardrails,” allowing your team to focus on your application instead of spending valuable time managing the hosting environments and its underlying infrastructure.</p>
                    </div>
                    <div className="vendors-logos">
                        <div className="vendor-logo">
                            <img src={AWSLogo} alt="AWS" className="AWS-logo" />
                        </div>
                        <div className="vendor-logo">
                            <img src={GoogleCloudLogo} alt="Google Cloud" className="GC-logo" />
                        </div>
                        <div className="vendor-logo">
                            <img src={OracleLogo} alt="Oracle Cloud" className="Oracle-logo" />
                        </div>
                        <div className="vendor-logo">
                            <img src={AzureLogo} alt="Microsoft Azure" className="Azure-logo" />
                        </div>
                    </div>
                </div>
                
                <div className='offerings-content'>
                    <h2 id="Offerings" className="offerings-title">SERVICE OFFERINGS</h2>
                    <div className='offerings-table'>
                    <Grid item xs={12} sm={12} md={12} lg={12} key={1}>
                        <div className='list-container'>
                            <div className='bar-text2'>
                                <img src={Bar} alt="Bar" />
                                <p>Our subset of commercial cloud services, from our CSP partnerships, are DISA approved for Impact Levels (IL) 2, 4, 5, 6 and include:</p>
                            </div>
                            <div className="left-list">
                                <div className='img-p-left'>
                                    <img src={AutoScaling} alt="" />
                                    <p >Auto-scaling capacity to match demand, offering optimized performance for the lowest available cost</p>
                                </div>
                                <div className='img-p-left'>
                                    <img src={BackupRecovery} alt="" />
                                    <p>Data Backup & Recovery</p>
                                </div>
                                <div className='img-p-left'>
                                    <img src={LoadBalancing} alt="" />
                                    <p>Load balancing of traffic to improve application responsiveness and minimize downtime</p>
                                </div>
                                <div className='img-p-left'>
                                    <img src={SystemSupport} alt="" />
                                    <p>System Update & Patch Support</p>
                                </div>
                                <div className='img-p-left'>
                                    <img src={AutomatedMA} alt="" />
                                    <p>Automated Monitoring & Alerting</p>
                                </div>
                            </div>
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} key={1}>
                        <div className='list-container'>
                            <div className='bar-text2'>
                                <img src={Bar} alt="Bar" />
                                <p>Cloud One supplements CSP commercial offerings with our own suite of common services tailored specifically to meet the requirments of our DoDs Air Force environment and include: </p>
                            </div>
                            <div className='right-list-container'>
                                <div className='right-list'>
                                    <div className='img-p-right'>
                                        <img src={Compliance} alt="" />
                                        <p>Compliance & Accreditation</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={CSSP} alt="" />
                                        <p>Cyber Security Service Provider (CSSP) Integration</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={Monitoring} alt="" />
                                        <p>Monitoring & Logging</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={Operating} alt="" />
                                        <p>Operating Analytics</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={DevSecOps} alt="" />
                                        <p>DevSecOps</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={AutomatedSVM} alt="" />
                                        <p>Automated Security & Vulnerability Management</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={IAM} alt="" />
                                        <p>Identity & Access Management</p>
                                    </div>
                                    <div className='img-p-right'>
                                        <img src={Collaboration} alt="" />
                                        <p>Collaboration & Support</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Grid>
                    </div> 

                </div>
                <div id="Development" className='development-content'>
                    <div className='development-BG-gradient'>
                        <div className='developmentOverlay'>
                            <div className='developmentTxtOverlay'>
                                <img src={DevelopmentOverlay} alt="Development Text Overlay" className="dev-overlay" />
                            </div>
                            <p className='DevParagraphs'>Cloud One offers secure and reliable development environments; enabling application proof-of-concept, development, integration, and testing activity in a government cloud environment that will meet your development and sustainment need.<br /><br />Cloud One includes a Cloud Boundary Protected infrastructure, which is secured by a FIPS compliant F5 VPN and provisioned with the necessary guardrails. Additionally, there is an optional Cloud Service Broker available for unfettered access. Cloud One also provides complete management and external access (incumbent on operator). ServiceNow is also included for incident support service requests, ticket management, tracking, and resolution.</p>
                            <div className='DevBox-container'>
                                <div className='DevBox'>
                                    <img src={DevIcon2} alt="Icon 2"></img>
                                    <p>The Cloud One boundary protected environment is for an MSO wanting to develop and work within the Cloud One guardrails (IL2 only).<br />
                                    <br />Cloud One procures and provides the initial standup of the environment, stamping it out to mimic our environment but without the operational restrictions in place and is more conducive for system devlopment.</p>
                                    <div className='responsibilities'>
                                        <h3>RESPONSIBILITIES</h3>
                                        <ul>
                                            <li style={{width: '95%'}}>Cloud One: procurement, setup and/or configuration, billing of MSO account, and maintenance of Cloud One boundary security</li>
                                            <li style={{paddingBottom: '7.6vh'}}>MSO/contractor: environment operation and maintenance</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='DevBox'>
                                    <img src={DevIcon3} alt="Icon 3"></img>
                                    <p>Cloud Service Broker is mission system owner managed. This environment is procured via the Cloud One contract, but is fully managed by the Mission System Owner (MSO) and/or contract vendor.<br />
                                    <br />This service is optional in cases where mission systems are capable of fully controlling a cloud environment or those that may prefer a sandbox environment outside of the Cloud One security boundary.</p>
                                    <div className='responsibilities'>
                                        <h3>RESPONSIBILITIES</h3>
                                        <ul>
                                            <li>Cloud One: procurement and billing</li>
                                            <li>MSO/contractor: environment operation and maintenance</li>
                                            <li style={{paddingBottom: '2vh', width: '95%'}}>Cloud One & MSO/contractor: negotiate responsibility for incident response</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="Migration" className='migrationOpts-content'>
                    <div className='migration-text'>
                        <h2>Migration Options</h2>
                        <div className='bar-text2 migOpts'> 
                            <img src={Bar} alt="Bar" />
                            <p style={{width: '80%'}}>With over 100 system migrations, you can trust Cloud One’s proven processes to migrate or deploy your mission system with ease.</p>
                        </div>
                        <p className="migOpts-para" style={{paddingBottom: '4vh'}}>We have two migration paths for production work loads: Migration as a Service (MaaS) and Hybrid.<br />
                        <br />Your migration costs will be dependent upon your migration path. Recurring costs are Compute and Store (C&S) utilization of cloud services, also known as cloud spend.<br />
                        <br />Registered users will have access to Cloud Service Provider (CSP) calculators to estimate your cloud C&S utilization chargers to plan ahead for your programmatic costs throughout the fiscal year.</p>
                    </div>
                    <div className='maas-and-hybrid'>
                    <div class="flip-card" onMouseOver={() => logToMatomo(['trackEvent', 'view', 'Maas'])}>
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                            <h2 className="title maas">MaaS</h2>
                            <div className="grey-bar" style={{width:'100%'}}/>
                            <p>Cloud One's full-service option, where you will have one contract with Cloud One and a vendor experienced in Cloud One migrations</p>
                            <p style={{ color: "white", fontWeight: "700", marginBottom: "7%", fontSize: '12px' }}>*Preferred MSO/contractor option</p>   
                        </div>
                        <div class="flip-card-back">
                            <h3>Financial Responsibilities</h3> 
                            <p>Cloud One</p> 
                            <p style={{paddingLeft:'15px', textAlign: 'left'}}>Pays for all onboarding, as well as Migration Orientation and Application Analysis</p> 
                            <p></p>
                            <p>MSO</p>
                            <p style={{paddingLeft:'15px', textAlign: 'left'}}>Pays for all migration labor costs (refactor and deployment) directly to the Cloud One PMO. MSO also pays for all compute and store (C&S) costs directly to the Cloud One PMO.

Note: Cloud One will provide yearly cost estimates for MaaS migrations.</p> 
                            </div>
                        </div>
                        </div>

                        <div class="flip-card" onMouseOver={() => logToMatomo(['trackEvent', 'view', 'Hybrid'])}>
                        <div class="flip-card-inner">
                            <div class="flip-card-front">
                                <div>
                            <h2 className="title hybrid">Hybrid</h2>
                            <div className="grey-bar" style={{width:'100%'}}/>
                            <p style={{width: '95%'}}>Self Migration, where you or the vendor of your choice migrates your application with Cloud One technical resources integrated into your team</p>
                        </div>
                        </div>
                            <div class="flip-card-back">
                            <h3>Financial Responsibilities</h3>  
                            <p>Cloud One</p>
                            <p style={{paddingLeft:'15px', textAlign: 'left'}}>Pays for all onboarding, as well as Migration Orientation and Application Analysis</p> 
                            <p></p>
                            <p>MSO</p>
                            <p style={{paddingLeft:'15px', textAlign: 'left'}}>Pays for all migration labor costs directly to their chosen vendor. MSO pays for any needed Cloud One enhanced labor support directly to the Coud One PMO. MSO also pays for all compute and store (C&S) costs directly to the Cloud One PMO. MSO is responsible to estimate Hybrid costs</p> 
                            </div>
                        </div>
                        </div>
                        {/* <div className='content-box'>
                            <h2 className="title maas">MaaS</h2>
                            <div className="grey-bar" />
                            <p>Cloud One's full-service option, where you will have one contract with Cloud One and a vendor experienced in Cloud One migrations</p>
                            <p style={{ color: "white", fontWeight: "700", marginBottom: "7%", fontSize: '12px' }}>*Preferred MSO/contractor option</p>
                        </div> */}
                        {/* <div className='content-box'>
                            <h2 className="title hybrid">Hybrid</h2>
                            <div className="grey-bar" />
                            <p style={{width: '95%'}}>Self Migration, where you or the vendor of your choice migrates your application with Cloud One technical resources integrated into your team</p>
                        </div> */}
                    </div>
                    <div className='components-content'>
                        <h3 style={{ marginTop: "4%", marginBottom: "1%", marginLeft: "4%" }}>Cloud One centrally funded and managed components</h3>
                        <div className='comp-table'>
                            <div className="comp-list" style={{paddingRight: "1%"}}>
                                <div className='img-text-comp'>
                                    <img src={SCCA} alt="" />
                                    <div>
                                        <h3>SCCA</h3>
                                        <p>Secure Cloud Computing Architecture</p>
                                    </div>
                                </div>
                                <div className='img-text-comp'>
                                    <img src={HybridMig} alt="" />
                                    <div>
                                        <h3>Hybrid Migration Support</h3>
                                        <p style={{width:'120%'}}>Code Analysis Tools (CAST) to assess custom applications; generates metrics for risk, complexity, size and overall health</p>
                                    </div>
                                </div>
                                <div className='img-text-comp'>
                                    <img src={CSSPmig} alt="" />
                                    <div>
                                        <h3>CSSP</h3>
                                        <p>Cyber Security Service Provider</p>
                                    </div>
                                </div>
                                <div className='img-text-comp'>
                                    <img src={CloudConnect} alt="" />
                                    <div>
                                        <h3 style={{width:'120%'}}>Cloud Connect Point Services</h3>
                                        <p style={{width:'110%'}}>Global Content Delivery Service (GCDS); supports commercial and NIPRNet connectivity</p>
                                    </div>
                                </div>
                            </div>
                            <div className="comp-list" style={{paddingLeft: "2%"}}>
                                <div className='img-text-comp'>
                                    <img src={Analysis} alt="" />
                                    <div>
                                        <h3>Analysis</h3>
                                        <p>Application Analysis</p>
                                    </div>
                                </div>
                                <div className='img-text-comp'>
                                    <img src={ATO} alt="" style={{width: '13%', marginLeft: '-1%'}}/>
                                    <div>
                                        <h3>ATO</h3>
                                        <p style={{width:'110%'}}>Authority to Operate for environments with ~40% inheritance of RMF controls at Control Correlation Identifier (CCI) level and rising</p>
                                    </div>
                                </div>
                                <div className='img-text-comp'>
                                    <img src={CnS} alt="" />
                                    <div>
                                        <h3>C&S</h3>
                                        <p style={{width: '190px'}}>Processing & invoicing</p>
                                    </div>
                                </div>
                                <div className='img-text-comp'>
                                    <img src={CommonTools} alt="" />
                                    <div>
                                        <h3>Common Tools & Services</h3>
                                        <p style={{width:'110%'}}>DevSecOps focus to ensure self-service and seamless deployments</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <div id="MigrationComparison" className='comparison-content'>
                <div className='comp-header'>
                    <h1>Migration Option Comparison</h1>
                    <hr className='comp-grey-line'/>
                    <div id="bar-text-titles" className='horizontal-division'>
                        <div className='leftmost-column' />
                        <img src={Bar} alt="Bar" style={{marginLeft: "-1.2%"}} />
                        <div className='data-column' >
                            <h3>MaaS</h3>
                            <h2 style={{marginLeft: '2px'}}>Migration as a Service</h2>
                        </div>
                        <img src={Bar} alt="Bar" style={{marginLeft: "-1.8%"}} />
                        <div className='data-column'>
                            <h3>HYBRID</h3>
                            <h2 style={{marginLeft: '2px'}}>Self-Migration</h2>
                        </div>
                    </div>
                </div>
                    <div className='Table-Box' style={{marginTop: "1.5%"}}>
                        <div className='grey-box' style={{marginLeft: "58%"}}/>
                        <div className='full-table'>
                            <div id="start" className='horizontal-division'>
                                <div className='leftmost-column'>
                                    <h3 style={{paddingBottom: "0%"}}>When can I start?</h3>
                                </div>
                                <div className='data-column'>
                                    <p style={{width: "92%", paddingRight: "6%"}}>Once you have completed the necessary onboarding steps (e.g., engagement meeting and artifact gathering), Cloud One will complete an Application Analysis and provide a detailed cost estimate. Once the MSOs approves and sends funding to Cloud One, the migration process can begin.</p>
                                </div>
                                <div className='data-column' style={{ borderTopLeftRadius: "25px", borderTopRightRadius: "25px" }}>
                                <div className='red-box'>
                                    <p style={{ padding: "5%", marginLeft: "0%", width: "102%" }}>Once onboarding is completed, your mission system team will complete an Application Analysis. If necessary, Cloud One will provide you with additional cost estimates. Once Cloud One and the MSO approve, the migration process can begin.</p>
                                </div>
                                </div>
                            </div>
                            <div id="work" className='horizontal-division'>
                                <div className='leftmost-column'>
                                    <h3>Who perfoms the work?</h3>
                                </div>
                                <div className='data-column'>
                                    <p>Cloud One + an experienced Cloud One migration vendor</p>
                                </div>
                                <div className='data-column'>
                                <div className='red-box'>
                                    <p style={{ padding: "5%", marginLeft: "0%", width: "102%" }}>Your MSO team + predefined Cloud One support</p>
                                    </div>
                                </div>
                            </div>
                            <div id="pays" className='horizontal-division'>
                                <div className='leftmost-column'>
                                    <h3>Who pays?</h3>
                                </div>
                                <div className='data-column'>
                                    <div className='blue-box'>
                                        <p style={{ color: "white", padding: "5%", marginLeft: "0%", width: "102%" }}>Cloud One Pays for activities from onboarding through application Analysis. Then, MSO pays for migration compute and store costs.</p>
                                    </div>
                                </div>
                                <div className='data-column'>
                                <div className='red-box'>
                                    <p style={{ padding: "5%", marginLeft: "0%", width: "102%" }}>Cloud One pays for onboarding activities. Then, MSO pays for Application Analysis, migration, compute, and store costs.</p>
                               </div>
                                </div>
                            </div>
                            <div id="support" className='horizontal-division'>
                                <div className='leftmost-column'>
                                    <h3>Is there support provided during the migration process?</h3>
                                </div>
                                <div className='data-column'>
                                    <p style={{width: "75%"}}>Cloud One provides support throughout the migration to include a 30-day DevOps burn-in period after your go-live date.</p>
                                </div>
                                <div className='data-column'>
                                <div className='red-box'>
                                    <p style={{ padding: "5%", marginLeft: "0%", width: "102%" }}>Migration support with any burn-in periods will be determined by MSO and the chosen vendor. Cloud One can provide additional support which would be negotiated during your registration and onboarding journey.</p>
                               </div>
                                </div>
                            </div>
                            <div id="risk" className='horizontal-division'>
                                <div className='leftmost-column'>
                                    <h3>What is the risk expectation with migrating our mission to the cloud?</h3>
                                </div>
                                <div className='data-column'>
                                    <p>LOW. Cloud One’s experienced migration vendors ensure industry best practice services that are in alignment with DoD and Air Force regulations for ATO inheritance.</p>
                                </div>
                                <div className='data-column'>
                                <div className='red-box'>
                                    <p style={{ padding: "5%", marginLeft: "0%", width: "102%" }}>MID. Your team has the discretion to determine how you will align with our environment. Cloud One is available to assist by providing decision-making support and facilitating ATO inheritance.</p>
                               </div>
                                </div>
                            </div>
                            <div id="training" className='horizontal-division'>
                                <div className='leftmost-column'>
                                    <h3>Is training available for Ops and Sustainment (O&S)?</h3>
                                </div>
                                <div className='data-column'>
                                    <p style={{width: "85%"}}>Cloud One provides Cloud One specific micro learning videos, access to free cloud training from our cloud service providers and Digital University, as well as access to Cloud One technical guidance and specifications documents.</p>
                                </div>
                                <div className='data-column' style={{ borderBottomLeftRadius: "25px", borderBottomRightRadius: "25px" }}>
                                    <p>Training is determined by MSO and your chosen vendor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;